import {EXP_RETAIL_INCENTIVE_BUNDLE} from '@/experiments';

import {useExperiment} from '../useExperiment';
import {useSiteData} from '../useSiteData';

export const useRetailIncentive = () => {
  const variant = useExperiment(EXP_RETAIL_INCENTIVE_BUNDLE);
  const {getUrl} = useSiteData();

  if (!variant) {
    return {};
  }

  const isSubscriptionIncentive = 'retail_subscription_incentive' === variant;
  const isSPRateIncentive = 'sp_rate_incentive' === variant;
  const isControl = 'control' === variant;

  const termsLink = getUrl('/waived-pos-fees/terms-and-conditions');

  return {
    isSubscriptionIncentive,
    isSPRateIncentive,
    isControl,
    termsLink,
  };
};
